import {
  Avatar,
  Box,
  Button,
  Card,
  Drawer,
  Typography,
  useTheme,
} from "@mui/material";
import {
  BiChevronRight,
  BiDownload,
  BiFile,
  BiHeart,
  BiMap,
  BiShareAlt,
  BiSolidXCircle,
} from "react-icons/bi";
import carImage from "../../assets/images/pattern.png";
import PageContainer from "src/components/common/components/PageContainer";
// import firstStep from "../../assets/images/first_step.png";
// import secStep from "../../assets/images/sec_step.png";
// import thirdStep from "../../assets/images/thi_step.png";
// import fourthStep from "../../assets/images/fourth_step.jpg";

import PointTabs from "src/components/dashboard/PointInfo";
import MemberOffers from "src/components/dashboard/Services";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "src/store/Store";
import Carousel from "src/components/slider";
import { BannersAPI, CustomerAPI } from "src/http/server-apis";
import { useQuery } from "react-query";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { NavLink } from "react-router-dom";
import EditProfileForm from "src/components/editProfileForm/EditProfileForm";
import { useEffect, useState } from "react";
import { setUserInfo } from "src/store/slice/user/userSlice";
import AddShareModal from "src/components/dashboard/share/AddShareModal";
import AboutSlider from "src/components/aboutSlider";
import Sh1 from "src/assets/images/about.png";

const Dashboard = () => {
  const theme: any = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const share = queryParams.get("share");
  const [open, setOpen] = useState(false);
  const [shareOpen, setShareOpen] = useState(false);

  const { UserInfo, UserID } = useSelector((state: RootState) => state?.user);
  const { data } = useQuery(
    ["get-banners"],
    () => BannersAPI("get", { postfix: `?Active=1` }),
    {
      refetchOnWindowFocus: false,
    }
  );

  const getUser = async () => {
    try {
      const res = await CustomerAPI("get", {
        params: `${UserID}`,
      });

      if (res?.status === 200) {
        dispatch(setUserInfo(res.data));
      }
    } catch (error) {}
  };
  const toggleDrawer = (v: boolean) => {
    setOpen(v);
    if (!v) getUser();
  };

  useEffect(() => {
    if (share) setShareOpen(true);
  }, [share]);

  return (
    <PageContainer title="Dashboard">
      <div className="grid grid-cols-1 gap-3 py-3">
        {UserID ? (
          <Card
            className="!bg-[url('./assets/images/white_wave.png')] bg-cover bg-no-repeat"
            sx={{
              backgroundColor: theme.palette.primary.main,
            }}
          >
            <Box className="grid grid-cols-2 items-center gap-3 mb-2 ">
              <Box component="div" className=" gap-1 flex ">
                <Typography variant="h6" color={theme.palette.gray.light}>
                  Hi
                </Typography>
                <Typography variant="h6" fontWeight={700} color={"#fff"}>
                  {UserInfo?.FirstName || "New User"}{" "}
                  {UserInfo?.MiddleName || ""} {UserInfo?.LastName || ""}
                </Typography>
                <Typography
                  variant="subtitle2"
                  color={theme.palette.warning.main}
                >
                  {UserInfo.CustomerName ? "Sliver Member (Tier 1)" : null}
                </Typography>
              </Box>

              <Box component="div" className="text-end flex justify-end">
                {+UserInfo?.Profile !== 100 ? (
                  <Button
                    variant="contained"
                    color="primary"
                    className="relative z-30 !text-[0.6rem] !border-white  !bg-white !text-red-800 !font-semibold "
                    size="small"
                    onClick={() => toggleDrawer(true)}
                  >
                    Update Profile
                  </Button>
                ) : null}
              </Box>
            </Box>

            <div className="grid grid-cols-3 gap-2 ">
              <Box
                component="div"
                className=" gap-1 flex flex-col justify-center "
              >
                <Typography variant="h3" fontWeight={700} color="#fff">
                  {+UserInfo?.Points || 0}
                </Typography>
                <Typography variant="body2" color={theme.palette.gray.light}>
                  Available Points
                </Typography>
              </Box>

              <Box
                component="div"
                className=" gap-1 flex flex-col justify-center"
              >
                <Typography variant="h3" fontWeight={700} color="#fff">
                  10
                </Typography>
                <Typography variant="body2" color={theme.palette.gray.light}>
                  Expiring in 30 Days
                </Typography>
              </Box>
              <NavLink to={"/membership"} className="flex justify-end items-center">
                <Button
                  color="primary"
                  className="relative z-30 !text-[0.6rem] !border-white  !bg-white !text-red-800 !font-semibold "
                  variant="contained"
                  size="small"
                  type="submit"
                >
                  Benefits
                </Button>
              </NavLink>
            </div>
          </Card>
        ) : (
          <Card className="!bg-[url('./assets/images/white_wave.png')] bg-cover bg-no-repeat relative">
            {/* <Box className="grid grid-cols-2 items-center gap-3 ">
              <Box component="div" className="py-5 gap-1 flex ">
                <Typography variant="h6" color={theme.palette.gray.main}>
                  Hi
                </Typography>
                <Typography
                  variant="h6"
                  fontWeight={700}
                  color={theme.palette.primary.main}
                >
                  {UserInfo?.FirstName || "New User"}{" "}
                  {UserInfo?.MiddleName || ""} {UserInfo?.LastName || ""}
                </Typography>
                {UserInfo.CustomerName ? (
                  <Typography
                    variant="subtitle2"
                    color={theme.palette.warning.main}
                  >
                    {UserInfo.CustomerName ? "Sliver Member (Tier 1)" : null}
                  </Typography>
                ) : null}
              </Box>
              <Box component="div" className="flex items-end justify-end">
                <Button
                  variant="contained"
                  color="primary"
                  // className="relative z-30 !border-white  !bg-white !text-red-800 !font-semibold "
                  size="small"
                  onClick={() => navigate("/auth/login")}
                >
                  Login
                </Button>
              </Box>
            </Box> */}
            <Box
              className="grid grid-cols-1 rounded-md relative p-2 !bg-gradient-to-r  from-[#e60121] to-[#8a0114]"
              sx={{
                backgroundColor: theme.palette.primary.main,
              }}
            >
              <Typography
                variant="h6"
                className="text-center !mb-1"
                fontWeight={900}
                color="white"
              >
                Welcome to Crown Honda
              </Typography>
              <Typography
                variant="body2"
                className="text-center !mb-1"
                fontWeight={900}
                color="white"
              >
                Drive, Earn, Sign Up !
              </Typography>

              <Typography
                variant="body2"
                className="text-center  !mb-1"
                color="white"
              >
                 Sign up to earn rewards and benefits.
              </Typography>

              <div className="flex items-center justify-center gap-2">
                <Button
                  variant="contained"
                  color="primary"
                  className="relative z-30 !min-w-[85px] !text-[0.6rem]  !bg-SecondaryText/50 !text-white !font-semibold "
                  sx={{
                    border:"1px solid white !important",
                    borderBottom:"2px solid white !important"
                  }}
                  size="small"
                  onClick={() => navigate("/auth/login")}
                >
                  Sign up 
                </Button>

                <Button
                  variant="contained"
                  color="primary"
                  className="relative z-30 !min-w-[85px] !text-[0.6rem]  !bg-SecondaryText/50 !text-white !font-semibold "
                  sx={{
                    border:"1px solid white !important",
                    borderBottom:"2px solid white !important"
                  }}
                  size="small"
                  onClick={() => navigate("/membership")}
                >
                  View Benefits
                </Button>
              </div>
              {/* <Link
                to="/auth/login"
                className="flex justify-between items-center relative"
              >
                <Typography
                  variant="subtitle1"
                  className="flex flex-1"
                  fontWeight={600}
                  color="white"
                >
                  Please login to unlock benefits.
                </Typography>

                <BiChevronRight size={20} color="white" />
              </Link>
              <div className="border-b border-white/40 my-2 "></div>
              <Link
                to="/auth/login"
                className="flex justify-between items-center relative"
              >
                <Typography
                  variant="subtitle2"
                  className="flex flex-1"
                  fontWeight={600}
                  color="white"
                >
                  Earn and redeem loyalty points on car services.
                </Typography>

                <BiChevronRight size={20} color="white" />
              </Link> */}
            </Box>

            {/* <div className="flex justify-between items-center gap-4 ">
              <Box
                component="div"
                className=" gap-1 flex flex-col justify-center "
              >
                <Typography
                  variant="h3"
                  fontWeight={700}
                  color={theme.palette.secondary.main}
                >
                  {+UserInfo?.Points || 0}
                </Typography>
                <Typography variant="body2" color={theme.palette.slate.dark}>
                  Available Points
                </Typography>
              </Box>

              <NavLink to={"/membership/2"}>
                <Button
                  color="primary"
                  className="flex !px-0"
                  variant="contained"
                  size="small"
                  type="submit"
                >
                  <span className="flex flex-1 px-3 ">Benefits</span>{" "}
                  <BiChevronRight size={30} />
                </Button>
              </NavLink>
            </div> */}
            <img
              src={carImage}
              alt="car"
              className="w-[30%] opacity-10 absolute right-0 top-0"
            />
          </Card>
        )}

        <Card>
          <Carousel imgKey="Banner" data={data?.data} fade={false} />
        </Card>
        <Card className="!bg-gradient-to-r  from-[#e60121] to-[#8a0114]">
          <Link
            to="/program"
            className="flex items-center justify-between text-white "
          >
            <div className="flex items-center gap-2">
              <BiFile size={20} />
              <Typography variant="subtitle1">Download Brochures</Typography>
            </div>
            <BiDownload size={20} />
          </Link>
        </Card>

        <MemberOffers />

        <Card>
          <PointTabs />
        </Card>

        {UserID ? (
          <Card className="!bg-gradient-to-r  from-[#e60121] to-[#8a0114]">
            <div
              onClick={() => setShareOpen(true)}
              className="flex items-center justify-between text-white "
            >
              <div className="flex items-center gap-2">
                <BiHeart size={20} />
                <Typography variant="subtitle1">Refer a Friend</Typography>
              </div>
              <BiShareAlt size={20} />
            </div>
          </Card>
        ) : null}

        <Card>
          <img src={Sh1} alt="sh" className="my-3  border" />

          <Typography
            variant="h5"
            className=" text-center !font-bold !leading-6 text-[#e60121]  italic !mb-5"
          >
            Welcome to Crown Honda – Your Trusted Partner in the Delhi NCR
            Region
          </Typography>
          <Typography variant="subtitle1" className="text-justify">
            Welcome to Crown Honda, your trusted Honda dealership in the Delhi
            NCR region since 2013. With a history in the automotive world dating
            back to 1996, we have built a strong reputation for honesty,
            reliability, and expertise. Our dedication to providing excellent
            customer service has earned us many awards, making us a favourite
            among Honda enthusiasts. Our showrooms are conveniently located in{" "}
            <span className="text-SecondaryText underline">Ghaziabad</span>,{" "}
            <span className="text-SecondaryText underline">Noida</span>,{" "}
            <span className="text-SecondaryText underline">Vaishali</span> and{" "}
            <span className="text-SecondaryText underline">New Delhi</span>{" "}
            ensuring that quality service is always nearby.
          </Typography>

          <div className="flex justify-center mt-4">
            <Button variant="contained" color="primary">
              <Link to="/about">Explore More</Link>
            </Button>
          </div>
        </Card>
        <Card>
          <div className="flex justify-between items-center  mb-2">
            <Typography
              variant="h5"
              fontWeight={700}
              color={theme.palette.gray.dark}
            >
              Showrooms & Workshops
            </Typography>
            <BiChevronRight
              size={30}
              color={theme.palette.gray.dark}
              onClick={() => navigate("/showrooms")}
            />
          </div>

          <AboutSlider />
        </Card>

        <div className="grid grid-cols-2 gap-2 mb-8">
          <Link to={"/showrooms/Ghaziabad"}>
            <Card className="flex items-center gap-2">
              <Avatar
                sx={{
                  backgroundColor: "#e60121",
                  width: 30,
                  height: 30,
                }}
              >
                <BiMap />
              </Avatar>

              <Typography variant="h6" className="flex flex-1">
                Ghaziabad
              </Typography>
            </Card>
          </Link>

          <Link to={"/showrooms/Vaishali"}>
            <Card className="flex items-center gap-2">
              <Avatar
                sx={{
                  backgroundColor: "#e60121",
                  width: 30,
                  height: 30,
                }}
              >
                <BiMap />
              </Avatar>

              <Typography variant="h6" className="flex flex-1">
                Vaishali
              </Typography>
            </Card>
          </Link>

          <Link to={"/showrooms/Noida"}>
            <Card className="flex items-center gap-2">
              <Avatar
                sx={{
                  backgroundColor: "#e60121",
                  width: 30,
                  height: 30,
                }}
              >
                <BiMap />
              </Avatar>

              <Typography variant="h6" className="flex flex-1">
                Noida
              </Typography>
            </Card>
          </Link>

          <Link to={"/showrooms/Delhi"}>
            <Card className="flex items-center gap-2">
              <Avatar
                sx={{
                  backgroundColor: "#e60121",
                  width: 30,
                  height: 30,
                }}
              >
                <BiMap />
              </Avatar>

              <Typography variant="h6" className="flex flex-1">
                New Delhi
              </Typography>
            </Card>
          </Link>
        </div>
      </div>

      <Drawer
        open={open}
        onClose={() => toggleDrawer(false)}
        anchor="bottom"
        sx={{
          "& .MuiPaper-root": {
            borderTopLeftRadius: 25,
            borderTopRightRadius: 25,
            overflow: "visible",
          },
        }}
      >
        <BiSolidXCircle
          className="absolute  right-1 "
          size={35}
          color={theme.palette.primary.main}
          onClick={() => toggleDrawer(false)}
        />
        <EditProfileForm close={() => toggleDrawer(false)} />
      </Drawer>

      {shareOpen ? (
        <AddShareModal
          open={shareOpen}
          close={() => setShareOpen(false)}
          actualValues={{}}
        />
      ) : null}
    </PageContainer>
  );
};

export default Dashboard;
