// eslint-disable-next-line import/no-anonymous-default-export
import { lazy } from "react";
import Loadable from "src/layouts/full/shared/loadable/Loadable";




const ShowroomsDetails = Loadable(
  lazy(() => import("src/views/apps/showrooms/ShowroomDetails"))
);

const Sales = Loadable(lazy(() => import("src/views/apps/sales")));
const Notification = Loadable(
  lazy(() => import("src/views/apps/notification"))
);
const Models = Loadable(lazy(() => import("src/views/apps/models")));

const CityDetails = Loadable(
  lazy(() => import("src/views/apps/models/CityDetails"))
);
const ElevateDetails = Loadable(
  lazy(() => import("src/views/apps/models/ElevateDetails"))
);

const CityHev = Loadable(lazy(() => import("src/views/apps/models/CityHev")));
const AmazeDetails = Loadable(
  lazy(() => import("src/views/apps/models/AmazeDetails"))
);
const NewAmaze = Loadable(
  lazy(() => import("src/views/apps/models/NewAmaze"))
);

const Profile = Loadable(lazy(() => import("src/views/apps/profile")));
const Showrooms = Loadable(lazy(() => import("src/views/apps/showrooms")));
const Workshop = Loadable(lazy(() => import("src/views/apps/workshop")));
const About = Loadable(lazy(() => import("src/views/apps/about")));
const MyCars = Loadable(lazy(() => import("src/views/apps/myCars")));
const Points = Loadable(lazy(() => import("src/views/apps/points")));
const ShareApp = Loadable(lazy(() => import("src/views/apps/shareApp")));
const Services = Loadable(lazy(() => import("src/views/apps/services")));
const MyBookings = Loadable(lazy(() => import("src/views/apps/myBookings")));
const TermsCondition = Loadable(
  lazy(() => import("src/views/apps/termsCondition"))
);
const ProgramBrochure = Loadable(
  lazy(() => import("src/views/apps/programBrochure"))
);
const ServiceDetails = Loadable(
  lazy(() => import("src/views/apps/services/ServiceDetail"))
);
const Faq = Loadable(lazy(() => import("src/views/apps/faq")));
const Support = Loadable(lazy(() => import("src/views/apps/support")));
const GolderMembership = Loadable(
  lazy(() => import("src/views/apps/membership"))
);
const ThankYou = Loadable(lazy(() => import("src/views/apps/thankYou")));

const MembershipDetail = Loadable(
  lazy(() => import("src/views/apps/membership/SliverMemDetails"))
);

const GoldMemDetails = Loadable(
  lazy(() => import("src/views/apps/membership/GoldMemDetails"))
);

const PlatinumMemDetails = Loadable(
  lazy(() => import("src/views/apps/membership/PlatinumMemDetails"))
);

const DiamondMemDetails = Loadable(
  lazy(() => import("src/views/apps/membership/DiamondMemDetails"))
);
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  path: "",
  children: [
    {
      path: "notification",
      element: <Notification />,
    },
    {
      path: "profile",
      element: <Profile />,
    },

    {
      path: "about",
      element: <About />,
    },

    {
      path: "models",
      children: [
        {
          path: "",
          element: <Models />,
        },
        {
          path: "elevate",
          element: <ElevateDetails />,
        },
        {
          path: "city-gen5",
          element: <CityDetails />,
        },
        {
          path: "city-hev",
          element: <CityHev />,
        },
        {
          path: "amaze",
          element: <AmazeDetails />,
        },
        {
          path: "new-amaze",
          element: <NewAmaze />,
        },
      ],
    },

    {
      path: "showrooms",
      children: [
        {
          path: "",
          element: <Showrooms />,
        },
        {
          path: ":id",
          element: <ShowroomsDetails />,
        },
      ],
    },

    {
      path: "workshop",
      element: <Workshop />,
    },

    {
      path: "myCars",
      element: <MyCars />,
    },

    {
      path: "shareApp",
      element: <ShareApp />,
    },

    {
      path: "thankYou",
      element: <ThankYou />,
    },

    {
      path: "membership",
      children: [
        {
          path: "",
          element: <GolderMembership />,
        },
        {
          path: "silver-member",
          element: <MembershipDetail />,
        },
        {
          path: "gold-member",
          element: <GoldMemDetails />,
        },
        {
          path: "platinum-member",
          element: <PlatinumMemDetails />,
        },
        {
          path: "diamond-member",
          element: <DiamondMemDetails />,
        },
      ],
    },

    {
      path: "points",
      element: <Points />,
    },
    {
      path: "services",
      children: [
        {
          path: "",
          element: <Services />,
        },
        {
          path: ":ServiceID",
          element: <ServiceDetails />,
        },
      ],
    },
    {
      path: "sales",
      children: [
        {
          path: "",
          element: <Sales />,
        },
        {
          path: ":ServiceID",
          children: [
            {
              path: "",
              element: <ServiceDetails />,
            },
            {
              path: "models",
              element: <Models />,
            },
            // {
            //   path: "models/:id",
            //   element: <CarDetails />,
            // },
          ],
        },
      ],
    },
    {
      path: "mybookings",
      children: [
        {
          path: "",
          element: <MyBookings />,
        },
      ],
    },
    {
      path: "program",
      element: <ProgramBrochure />,
    },
    {
      path: "terms",
      element: <TermsCondition />,
    },
    {
      path: "faq",
      element: <Faq />,
    },
    {
      path: "support",
      element: <Support />,
    },
  ],
};
