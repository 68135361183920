import * as React from "react";
import { Typography, useTheme } from "@mui/material";
import { BiChevronRight } from "react-icons/bi";
import { useNavigate } from "react-router";

import PointsInfoTabs from "../points/PointsInfoTabs";

function PointTabs() {
  const theme: any = useTheme();
  const navigate = useNavigate();

  return (
    <>
      <div className="flex justify-between items-center  mb-2">
        <Typography
          variant="h5"
          fontWeight={700}
          color={theme.palette.gray.dark}
        >
       Loyalty Points
        </Typography>
        <BiChevronRight
          size={30}
          color={theme.palette.gray.dark}
          onClick={() => navigate("/points")}
        />
      </div>
      <PointsInfoTabs />
    </>
  );
}

export default PointTabs;
