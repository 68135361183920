import { createSlice } from "@reduxjs/toolkit";
import dayjs from "dayjs";

const initialState = {
  UserID: null,
  UserInfo: {
    Flag: 0,
    CustomerID: 0,
    CustomerName: null,
    Date: "",
    Gender: null,
    MobileNo: "",
    EmailID: null,
    Profile: null,
    Points: null,
    Platform: "",
    VehicleNumber: "",
    Selfie:"",
    Rights: [],
    FirstName:"",
    LastName:"",
    MiddleName:"",
    Points:"",
    ProfileScore:"",
    Profile:"",
    DOA:"",
    DOB:"",
  },
  timestamp: null,

  isAuthenticated: false,
};

export const UserSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUserID: (state, action) => {
      state.UserID = action.payload;

    },
    setUserInfo: (state, action) => {
      state.UserInfo = action.payload;
      state.timestamp = action?.payload ? dayjs() : null;
      state.Year = initialState.Year;
      state.isAuthenticated = true;
    
    },
    updaeUserInfo: (state, action) => {
      state.UserInfo = action.payload;
    },
    resetAuthInfo: (state) => {
      state.UserID = null;
      state.UserInfo = {
        Rights: [],
      };
      state.timestamp = null;
      state.isAuthenticated = false;
    },
  },
});

export const { setUserID, setUserInfo, resetAuthInfo, updaeUserInfo } = UserSlice.actions;

export default UserSlice.reducer;
