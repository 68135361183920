import React from "react";
import Slider from "react-slick";
import SlideFirst from "src/assets/images/slide1.png";
import SlideSec from "src/assets/images/slide2.png";
import SlideThird from "src/assets/images/slide3.png";
import SlideFourth from "src/assets/images/slide4.png";
function AboutSlider() {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000
  };
  return (
    <div className="slider-container ">
      <Slider {...settings}>
        <div>
          <img src={SlideFirst} alt="slide1" className="rounded-md border-2 border-white" />
        </div>
        <div>
          <img src={SlideSec} alt="slide2" className="rounded-md border-2 border-white" />
        </div>
        <div>
          <img src={SlideThird} alt="slide3" className="rounded-md border-2 border-white" />
        </div>
        <div>
          <img src={SlideFourth} alt="slide4" className="rounded-md border-2 border-white" />
        </div>
      </Slider>
    </div>
  );
}

export default AboutSlider;
