const baselightTheme = {
  direction: "ltr",
  palette: {
    secondary: {
      light: "#86909c",
      main: "#082f49",
      dark: "#051c2c",
      contrastText: "#ffffff",
    },
    primary: {
      lighter: "#faccd3",
      light: "#f599a6",
      main: "#e60121",
      dark: "#8a0114",
      contrastText: "#ffffff",
    },

    


    darkblue: {
      lighter: "#cdd5dd",
      light: "#68829a",
      main: "#042f57",
      dark: "#021c34",
      contrastText: "#ffffff",
    },

    slate: {
      main: "#cbd5e1",
      light: "#f1f5f9",
      dark: "#64748b",
      contrastText: "#ffffff",
    },

    success: {
      main: "#13deb9",
      light: "#71ebd5",
      lighter: "#d0f8f1",
      veryLighter: "#e1fcf8",
      dark: "#09a78f",

      contrastText: "#ffffff",
    },

    error: {
      main: "#fa896b",
      light: "#fcb8a6",
      lighter: "#fde4eb",
      dark: "#de0000",
      contrastText: "#ffffff",
    },

    warning: {
      main: "#ffae1f",
      light: "#ffce79",
      lighter: "#fef6e8",
      dark: "#66460c",
      contrastText: "#ffffff",
    },

    info: {
      main: "#539BFF",
      light: "#EBF3FE",
      dark: "#1682d4",
      contrastText: "#ffffff",
    },

    purple: {
      main: "#6610f2",
      light: "#a370f7",
      lighter: "#e0cffc",
      dark: "#3d0a91",
      contrastText: "#ffffff",
    },

    gray: {
      lighter: "#ebebeb",
      light: "#e0e0e0",
      main: "#cccccc",
      dark: "#525252",
      contrastText: "#ffffff",
    },

    text: {
      primary: "#2A3547",
      secondary: "#2A3547",
      contrastText: "#ffffff",
    },
    action: {
      disabledBackground: "rgba(73,82,88,0.12)",
      hoverOpacity: 0.02,
      hover: "#f6f9fc",
    },
    divider: "#e5eaef",
    background: {
      default: "#ffffff",
    },
  },
};

export { baselightTheme };
